<!--
 * @Author: huadan
 * @Date: 2021-04-14 11:01:12
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-11-13 16:03:13
 * @Description: 订单列表
-->

<style lang="less" scoped>
.project-list {
  padding-top: 30px;

  .project-list-table {
    margin: 0 20px;

    .filter-button {
      width: 88px;
      height: 32px;
      text-align: center;
    }

    .filter1-button {
      width: 130px;
    }

    .filter3-button {
      width: 88px;
    }

    .table-operations {
      margin-top: 20px;
      margin-bottom: 10px;

      .range-picker {
        width: 100% !important;
      }
    }

    .quit {
      color: @text_color_3;
    }
  }
}

.custom-page-size-box {
  display: flex;
  height: 45px;
  justify-content: right;
  align-items: center;
}

.pagination {
}

.custom-page-size {
  margin-left: 9px;
  width: 90px;
  height: 32px;
}

.input-unit {
  margin-left: 5px;
}

.input-box {
  position: relative;
}
.dropup {
  z-index: 99;
  position: absolute;
  font-size: 20px;
  right: 10px;
  top: 5px;
  cursor: pointer;
}

.pdf-config {
  width: 60px;
}

/deep/.mb-2.ant-row.ant-form-item {
  display: flex;
}
/deep/.ant-col.ant-col-2.ant-form-item-label {
  width: 100px;
  text-align: center;
}
/deep/.ant-form-item-control-wrapper {
  flex: 1;
}
</style>

<template>
  <NeoPageLayout class="project-list">
    <div class="project-list-table">
      <!--  订单列表表格过滤 -->
      <a-radio-group v-model="radioGroup">
        <a-radio-button @click="clickTab" class="filter-button" value="">
          {{ $t('orderList.all') }}
        </a-radio-button>
        <a-radio-button @click="clickTab" value="1" v-if="$role('order_orderList_customer')">
          {{ $t('orderList.to_quote') }}
          <NeoTag class="ml-1" type="warning" size="mini">{{ toWaitQuoteCount }}</NeoTag>
        </a-radio-button>
        <a-radio-button @click="clickTab" value="2" v-if="$role('order_orderList_customer')">
          {{ $t('orderList.to_confirm_quote') }}
          <NeoTag class="ml-1" type="warning" size="mini">{{ toConfirmQuoteCount }}</NeoTag>
        </a-radio-button>
        <a-radio-button @click="clickTab" value="5" v-if="$role('order_orderList_customer')">
          {{ $t('orderList.to_confirm_final') }}
          <NeoTag class="ml-1" type="warning" size="mini">{{ toConfirmFinalCount }}</NeoTag>
        </a-radio-button>
        <a-radio-button @click="clickTab" value="1" v-if="$role('order_orderList_provider')">
          {{ $t('orderList.to_quote') }}
          <NeoTag class="ml-1" type="warning" size="mini">{{ toQuoteCount }}</NeoTag>
        </a-radio-button>
        <a-radio-button @click="clickTab" value="4" v-if="$role('order_orderList_provider')">
          {{ $t('orderList.in_production') }}
          <NeoTag class="ml-1" type="warning" size="mini">{{ inProductionCount }}</NeoTag>
        </a-radio-button>
        <a-radio-button @click="clickTab" value="6">
          {{ $t('orderList.final_modify') }}
          <NeoTag class="ml-1" type="warning" size="mini">{{ finalModifyCount }}</NeoTag>
        </a-radio-button>
      </a-radio-group>
      <!--  订单列表表格查询 -->
      <div class="table-operations">
        <a-form-model ref="form" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-row>
            <a-col :span="11">
              <a-form-model-item :label="$t('orderList.order_status')" class="mb-2">
                <a-select
                  v-model="statusSelect"
                  :placeholder="$t('orderList.select_order_status')"
                  mode="multiple"
                  @change="onStatusChange"
                >
                  <a-select-option
                    v-for="item in Object.entries(localeDict.orderStatusSelect)"
                    :key="item[0]"
                    :value="item[0]"
                  >
                    {{ item[1] }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="11">
              <a-form-model-item
                class="mb-2"
                :label="$t('orderList.order_name')"
                :wrapperCol="{ span: 22 }"
                prop="orderCodeOrName"
              >
                <a-input v-model="form.orderCodeOrName" :placeholder="$t('orderList.input_order_nn')" />
              </a-form-model-item>
            </a-col>
            <a-col :span="2">
              <a-form-model-item class="mb-2" :wrapper-col="{ span: 1, offset: 1 }">
                <a-button @click="resetSearch()" class="filter3-button ml-2">{{ $t('reset') }}</a-button>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="11">
              <a-form-model-item class="mb-2" :label="$t('orderList.order_duedate')" prop="dueDate">
                <a-range-picker
                  v-model="dueDate"
                  class="range-picker"
                  :show-time="{
                    format: 'HH:mm',
                    defaultValue: [moment('00:00', 'HH:mm'), moment('23:59', 'HH:mm')],
                  }"
                  format="YYYY-MM-DD HH:mm"
                  @change="onDueDateChange"
                >
                  <a-icon slot="suffixIcon" type="calendar" />
                </a-range-picker>
              </a-form-model-item>
            </a-col>
            <a-col :span="11">
              <a-form-model-item class="mb-2" :label="$t('orderList.order_createdate')" :wrapperCol="{ span: 22 }">
                <a-range-picker
                  v-model="presentDate"
                  class="range-picker"
                  :show-time="{
                    format: 'HH:mm',
                    defaultValue: [moment('00:00', 'HH:mm'), moment('23:59', 'HH:mm')],
                  }"
                  format="YYYY-MM-DD HH:mm"
                  @change="onCreateDateChange"
                >
                  <a-icon slot="suffixIcon" type="calendar" />
                </a-range-picker>
              </a-form-model-item>
            </a-col>
            <a-col :span="2">
              <a-form-model-item class="mb-2" :wrapper-col="{ span: 1, offset: 1 }">
                <a-button type="primary" @click="searchTable()" class="filter3-button ml-2">{{
                  $t('search')
                }}</a-button>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="11">
              <a-form-model-item :label="$t('newOrder.product_line')" class="mb-2">
                <a-select
                  v-model="productLineIds"
                  :placeholder="$t('newOrder.product_line_holder')"
                  mode="multiple"
                  :filter-option="langFilterOption"
                >
                  <a-select-option v-for="(item, index) in cusProductLineList" :key="index" :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
      <a-row type="flex" justify="space-between">
        <a-col :span="6">
          <a-button type="primary mb-1 mr-2" :loading="pdfLoading" @click="exportQuotation()">{{
            $t('orderList.order_export_quotation')
          }}</a-button>
          <a-select
            v-model="pdf.format"
            :showArrow="false"
            :placeholder="$t('pdf.format')"
            class="pdf-config mb-1 mr-2"
          >
            <template v-for="item of localeDict.pageFormat">
              <a-select-option :key="item" :value="item">
                {{ item }}
              </a-select-option>
            </template>
          </a-select>
          <a-select
            v-model="pdf.landscape"
            :showArrow="false"
            :placeholder="$t('pdf.landscape')"
            class="pdf-config mb-1"
          >
            <template v-for="(item, key) of localeDict.pageLandscape">
              <a-select-option :key="key" :value="key">
                {{ item }}
              </a-select-option>
            </template>
          </a-select>
        </a-col>
        <a-col v-if="$role('payment_customer') || $role('payment_customer_admin')">
          <a-select
            default-value="present_date|desc"
            v-model="sortValue"
            :style="{ width: '190px' }"
            @change="handleChange"
          >
            <a-select-option value="present_date|desc">
              {{ $t('orderList.sort_createdate_des') }}
            </a-select-option>
            <a-select-option value="present_date|asc"> {{ $t('orderList.sort_createdate_asc') }}</a-select-option>
            <a-select-option value="due_date|desc"> {{ $t('orderList.sort_duedate_des') }}</a-select-option>
            <a-select-option value="due_date|asc"> {{ $t('orderList.sort_duedate_asc') }}</a-select-option>
          </a-select>
        </a-col>
      </a-row>

      <!--  订单列表表格 -->
      <!-- :scroll="{ x: $g.scrollXWidth }" -->
      <a-table
        class="striped no-border"
        rowKey="id"
        :columns="tableColumns"
        :data-source="dataSource"
        :pagination="false"
        :loading="loading"
        size="middle"
        :rowSelection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onRowChange,
        }"
      >
        <!--  操作列 -->
        <span slot="action" slot-scope="text, record">
          <!-- <a
            @click="
              $router.push({
                path: $g.route.order_orderInfo,
                query: {
                  id: record.id,
                },
              })
            "
            >{{ $t('orderList.order_view_details') }}</a
          > -->
          <!-- 复制订单 -->
          <a-popconfirm
            v-if="!$role('order_orderList_provider')"
            class="ml-3"
            :title="$t('orderList.copy_info', { name: record.name })"
            :ok-text="$t('confirm')"
            :cancel-text="$t('cancel')"
            placement="topRight"
            @confirm="() => onCopy(record.id)"
            @cancel="() => {}"
          >
            <a href="#">{{ $t('copy') }}</a>
          </a-popconfirm>
          <!-- 删除: 订单状态为”已取消“时，出现”删除“按钮 -->
          <a-popconfirm
            v-if="record.status === 3 && !$role('order_orderList_provider')"
            class="ml-3"
            :title="$t('orderList.del_info', { name: record.name })"
            :ok-text="$t('confirm')"
            :cancel-text="$t('cancel')"
            placement="topRight"
            @confirm="() => onDelete(record.id)"
            @cancel="() => {}"
          >
            <a href="#">{{ $t('delete') }}</a>
          </a-popconfirm>
        </span>
        <span slot="orderStatus" slot-scope="text">
          <span v-if="text == 3 || text == 8" class="quit">
            {{ localeDict.orderStatus[text] }}
          </span>
          <span v-else>
            {{ localeDict.orderStatus[text] }}
          </span>
        </span>
      </a-table>

      <!--  分页 -->
      <div class="custom-page-size-box mt-3 fr">
        <a-pagination
          class="pagination"
          v-model="current"
          :total="total"
          :page-size="pageSize"
          @change="onChange"
          @showSizeChange="onShowSizeChange"
        />

        <div class="input-box">
          <a-input type="text" class="custom-page-size" v-model="customPageSize" @keyup.enter="submit" />
          <a-dropdown placement="topCenter" :trigger="['click']">
            <span class="dropup" @click="(e) => e.preventDefault()">^</span>
            <a-menu slot="overlay">
              <a-menu-item>
                <a @click="selectdrop(10)">10</a>
              </a-menu-item>
              <a-menu-item>
                <a @click="selectdrop(20)">20</a>
              </a-menu-item>
              <a-menu-item>
                <a @click="selectdrop(30)">30</a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>

        <span class="input-unit">条/页</span>
      </div>
    </div>
  </NeoPageLayout>
</template>

<script>
import NeoTag from '@/components/common/NeoTag'
import moment from 'moment'
import debounce from 'lodash/debounce'
import Cookies from 'js-cookie'
import { downloadBlob } from '@/utils/utils'
const { storageKey } = window.$g
// const { validate } = window.$g

let isKeepFilterRes = false
export default {
  name: 'ProjectList',
  components: { NeoTag },
  data() {
    return {
      // 产线列表
      cusProductLineList: [],
      // 选择的产线
      productLineIds: [],
      empty: '---',
      localeDict: window.$g.localeDict,
      loading: false,
      pdfLoading: false,
      //订单列表表格列
      columns: [
        {
          title: this.$t('orderList.order_num'),
          dataIndex: 'code',
          key: 'code',
          align: 'center',
          width: 80,
          customRender: (text) => {
            return {
              children: text || this.empty,
            }
          },
        },
        {
          title: this.$t('orderList.order_name'),
          dataIndex: 'name',
          key: 'name',
          width: 220,
          customRender: (text, record) => {
            return {
              children: (
                <a
                  class="line-feed uline dis-ib ellipsis"
                  onClick={() => this.goDetail(record)}
                  title={text}
                  style="width: 200px">
                  {text}
                </a>
              ),
            }
          },
        },
        {
          title: this.$t('orderList.order_status'),
          dataIndex: 'orderStatus',
          key: 'orderStatus',
          width: 100,
          scopedSlots: { customRender: 'orderStatus' },
          customRender: (text) => {
            const orderStatus = this.localeDict.orderStatus
            const paymentStatus = this.localeDict.paymentStatus
            return {
              children: orderStatus[text] || paymentStatus[text] || this.empty,
            }
          },
        },
        {
          title: this.$t('orderList.order_duedate'),
          dataIndex: 'dueDate',
          key: 'dueDate',
          width: '120px',
          customRender: (time) => {
            return {
              children: time ? moment(time).format(this.$t('dateFormat.f')) : this.empty,
            }
          },
        },
        {
          title: this.$t('orderList.order_createdate'),
          dataIndex: 'presentDate',
          key: 'presentDate',
          width: '120px',
          customRender: (time) => {
            return {
              children: time ? moment(time).format(this.$t('dateFormat.f')) : this.empty,
            }
          },
        },
        {
          title: this.$t('orderList.order_action'),
          dataIndex: 'action',
          key: 'action',
          scopedSlots: { customRender: 'action' },
          width: '60px',
        },
      ],
      //行选择器
      radioGroup: '',
      //订单列表表格数据
      dataSource: [],
      //订单列表表格每页显示大小
      pageSize: 10,
      //订单列表表格当前页
      current: 1,
      //订单列表表格总数
      total: 50,
      labelCol: { span: 2 },
      wrapperCol: { span: 20 },
      //查询参数
      form: {
        status: undefined,
        orderCodeOrName: '',
        startDueDate: undefined,
        endDueDate: undefined,
        startPresentDate: undefined,
        endPresentDate: undefined,
      },
      searchForm: {},
      //排序
      defaultSortValue: 'present_date|desc',
      defaultOrder: 'desc',
      defaultOrderField: 'present_date',
      sortValue: 'present_date|desc',
      order: 'desc',
      orderField: 'present_date',
      //状态筛选
      status: '',
      dueDate: undefined,
      presentDate: undefined,
      statusSelect: undefined,
      //订单列表表格行选择
      selectedRowKeys: [],
      selectedRows: [],
      // 等待报价个数
      toWaitQuoteCount: 0,
      //待确认报价个数
      toConfirmQuoteCount: 0,
      //待确认终稿个数
      toConfirmFinalCount: 0,
      //等待报价个数
      toQuoteCount: 0,
      //生产中个数
      inProductionCount: 0,
      //终稿修改中个数
      finalModifyCount: 0,
      // 自定义分页的条数
      customPageSize: 10,
      // pdf导出参数
      pdf: {
        format: 'A4',
        landscape: 'false',
      },
    }
  },

  computed: {
    // 1.报价单是否可以查看或导出：
    isExportOrder() {
      let result = { value: false, popInfo: '', props: [] }
      const isDefined = (item) => {
        return item !== undefined && item !== null
      }
      if (this.selectedRows.every((item) => item.status == 1 || !isDefined(item.quotePrice))) {
        result.value = false
        result.rows = []
        result.popInfo = this.$t('orderList.noexport_orderstatus')
        return result
      } else {
        if (this.selectedRows.every((item) => item.status != 1 && isDefined(item.quotePrice))) {
          // 一点也不包括
          result.value = true
          result.rows = this.selectedRows
          result.props = this.selectedRowKeys
          return result
        } else {
          // 部分包括=>过滤
          result.value = true
          result.rows = this.selectedRows.filter((item) => item.status != 1 && isDefined(item.quotePrice))
          result.props = this.selectedRows
            .filter((item) => item.status != 1 && isDefined(item.quotePrice))
            .map((item) => item.id)
          return result
        }
      }
    },

    tableColumns() {
      const start = [...this.columns.slice(0, 3)]
      const last = [...this.columns.slice(3)]
      const supplierPm = {
        title: this.$t('orderList.delivery_manager'),
        dataIndex: 'supplierPmName',
        key: 'supplierPmName',
        width: 120,
        customRender: (text) => {
          return {
            children: (
              <span class="dis-ib ellipsis" title={text} style="width: 100px">
                {text || this.empty}
              </span>
            ),
          }
        },
      }
      const orderManager = {
        title: this.$t('orderList.order_manager'),
        dataIndex: 'presentManagerName',
        key: 'presentManagerName',
        width: 120,
        customRender: (text) => {
          return {
            children: (
              <span class="dis-ib ellipsis" title={text} style="width: 100px">
                {text || this.empty}
              </span>
            ),
          }
        },
      }
      if (this.$role('order_orderList_customer') && !this.$role('order_orderList_presentManager')) {
        return [...start, supplierPm, ...last]
      } else if (this.$role('order_orderList_customer') && this.$role('order_orderList_presentManager')) {
        return [...start, orderManager, supplierPm, ...last]
      } else if (this.$role('order_orderList_provider')) {
        return [...start, orderManager, supplierPm, ...last]
      } else {
        return [...start, ...last]
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    if (from.path === '/Kingsoft/order/orderInfo') {
      isKeepFilterRes = true
    } else {
      isKeepFilterRes = false
    }
    next()
  },
  created() {
    this.getCusProductLineList()
    this.statusCount()
    const tab = this.$route.query.tabVal
    if (tab) {
      this.radioGroup = tab
      this.status = tab
    }
    if (isKeepFilterRes) {
      this.getScreenData()
    } else {
      this.fetch()
    }
  },

  methods: {
    langFilterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    // 获取过滤条件
    getScreenData() {
      if (!localStorage.getItem('order_list_screenData')) {
        this.fetch()
        return
      }
      const screenData = JSON.parse(localStorage.getItem('order_list_screenData'))
      this.radioGroup = screenData.radioGroup || ''
      this.statusSelect = screenData.searchForm.statusSelect
      this.form.orderCodeOrName = screenData.searchForm.orderCodeOrName || ''
      this.dueDate = screenData.searchForm.dueDate || undefined
      this.presentDate = screenData.searchForm.presentDate || undefined
      this.productLineIds = screenData.searchForm.productLineIds || []
      this.order = screenData.searchForm.order || this.defaultOrder
      this.orderField = screenData.searchForm.orderField || this.defaultOrderField
      this.status = screenData.searchForm.status || ''
      this.sortValue = screenData.searchForm.sortValue || this.defaultSortValue
      this.pageSize = screenData.pageParmams ? screenData.pageParmams.pageSize : 10
      this.current = screenData.pageParmams ? screenData.pageParmams.current : 1
      this.form.status =
        screenData.searchForm.statusSelect && screenData.searchForm.statusSelect.length
          ? screenData.searchForm.statusSelect.toString()
          : ''
      this.form.startDueDate = screenData.searchForm.startDueDate
      this.form.endDueDate = screenData.searchForm.endDueDate
      this.form.startPresentDate = screenData.searchForm.startPresentDate
      this.form.endPresentDate = screenData.searchForm.endPresentDate
      this.searchForm = { ...this.form }
      this.fetch(this.searchForm)
    },
    // 跳转订单详细
    goDetail(record) {
      const screenData = {
        radioGroup: this.radioGroup,
        searchForm: {
          statusSelect: this.statusSelect,
          orderCodeOrName: this.form.orderCodeOrName,
          dueDate: this.dueDate,
          startDueDate: this.form.startDueDate,
          endDueDate: this.form.endDueDate,
          presentDate: this.presentDate,
          startPresentDate: this.form.startPresentDate,
          endPresentDate: this.form.endPresentDate,
          productLineIds: this.productLineIds,
          order: this.order,
          orderField: this.orderField,
          status: this.status,
          sortValue: this.sortValue,
        },
        pageParmams: {
          pageSize: this.pageSize,
          current: this.current,
        },
      }
      localStorage.setItem('order_list_screenData', JSON.stringify(screenData))
      this.$router.push({
        path: this.$g.route.order_orderInfo,
        query: {
          id: record.id,
        },
      })
    },
    // 产线列表
    async getCusProductLineList() {
      try {
        const data = await this.$http('cusProductLineEntry')
        this.cusProductLineList = data
      } catch (error) {
        this.$httpNotify(error)
      }
    },
    clickTab({ target }) {
      const val = target.value
      this.status = val
      this.current = 1
      this.emptySearch()
      this.emptySelected()
      this.fetch()
    },
    // 清空多选
    emptySelected() {
      this.selectedRowKeys = []
      this.selectedRows = []
    },
    // 清空搜索条件
    emptySearch() {
      this.current = 1
      this.form.status = undefined
      this.form.orderCodeOrName = ''
      this.form.startDueDate = undefined
      this.form.endDueDate = undefined
      this.form.startPresentDate = undefined
      this.form.endPresentDate = undefined
      this.dueDate = undefined
      this.presentDate = undefined
      this.statusSelect = undefined
      this.productLineIds = []
      this.resetSort()
      this.searchForm = { ...this.form }
    },
    resetSort() {
      this.sortValue = this.defaultSortValue
      this.order = this.defaultOrder
      this.orderField = this.defaultOrderField
    },
    onRowChange(selectedRowKeys, selectedRows) {
      //选中的数据的key
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    //页码改变的回调，参数是改变后的页码及每页条数
    onChange(page, pageSize) {
      this.current = page
      this.pageSize = pageSize
      this.fetch(this.searchForm)
      this.selectedRowKeys = []
      this.selectedRows = []
    },

    //pageSize 变化的回调
    onShowSizeChange(size, current) {
      this.current = current
      this.pageSize = Number(size)
      this.fetch(this.searchForm)
    },
    //表格更新数据
    fetch: debounce(
      async function (params = {}) {
        try {
          this.loading = true
          const form = JSON.parse(JSON.stringify(params))
          delete form.status
          const data = await this.$http('orderList', {
            limit: this.pageSize,
            page: this.current,
            order: this.order,
            orderField: this.orderField,
            ...form,
            status: this.status,
            productLineIds: this.productLineIds.toString(),
          })
          this.loading = false
          this.dataSource = data.list
          this.total = data.total
        } catch (err) {
          this.loading = false
          this.$httpNotify(err)
        }
      },
      200,
      { leading: true, trailing: false }
    ),
    clearTimer() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
    },
    async statusCount() {
      try {
        const data = await this.$http('orderStatusCount')
        if (data) {
          this.toWaitQuoteCount = data.filter((v) => v.status === 1)[0].count
          this.toConfirmQuoteCount = data.filter((v) => v.status === 2)[0].count
          this.toConfirmFinalCount =
            Number(data.filter((v) => v.status === 5)[0].count) + Number(data.filter((v) => v.status === 7)[0].count)
          this.toQuoteCount = data.filter((v) => v.status === 1)[0].count
          this.inProductionCount = data.filter((v) => v.status === 4)[0].count
          this.finalModifyCount = data.filter((v) => v.status === 6)[0].count

          this.clearTimer()
          this.timer = setTimeout(this.statusCount, 120000)
        }
      } catch (err) {
        console.warn('loopCount_list error ==>', err)
      }
    },

    isNoSearchOption() {
      const result =
        !this.form.orderCodeOrName & !this.statusSelect & !this.dueDate & !this.presentDate &&
        !this.productLineIds.length

      return result
    },

    //查询
    searchTable() {
      this.current = 1
      this.searchForm = { ...this.form }
      this.status = this.form.status
      this.resetSort()
      // 如果没有筛选条件，不跳转tab
      if (this.isNoSearchOption()) {
        this.status = this.radioGroup
        this.fetch(this.searchForm)
      } else {
        this.radioGroup = ''
        this.fetch(this.searchForm)
      }
    },

    // 重置表单
    resetSearch() {
      this.emptySearch()
      this.status = this.radioGroup
      this.fetch()
    },
    sortClass(sortData, key) {
      const groupBy = (array, f) => {
        let groups = {}
        array.forEach((o) => {
          let group = JSON.stringify(f(o))
          groups[group] = groups[group] || []
          groups[group].push(o)
        })
        return Object.keys(groups).map((group) => {
          return groups[group]
        })
      }
      const sorted = groupBy(sortData, (item) => {
        return item[key] // 返回需要分组的对象
      })
      return sorted
    },
    //导出报价单
    async exportQuotation() {
      const rows = this.selectedRows
      if (!rows.length) {
        return this.$message.warning(this.$t('orderList.noselected'))
      }
      const isExportOrder = this.isExportOrder
      const { supplierId, supplierName } = isExportOrder.rows[0] ?? {}
      let isCusAgencyAllSame = true //下单方实体是否都一样
      let exportType = null
      isExportOrder.rows.forEach((item) => {
        if (item.supplierId !== supplierId) {
          isCusAgencyAllSame = false
        }
      })
      // 下单方实体都一样 => pdf
      // 下单方实体不一样 => zip
      if (!isCusAgencyAllSame) {
        exportType = 'zip'
      } else {
        exportType = 'pdf'
      }

      if (!isExportOrder.value) {
        return this.$message.warning(isExportOrder.popInfo)
      }

      const ids = isExportOrder.props
      const _ids = ids.length ? ids.join(',') : ''
      let name = ''
      let type = ''
      const pdfInfoList = []
      if (ids.length <= 1) {
        name = `${isExportOrder?.rows[0].name}_${this.$t('orderInfo.quote')}`
        type = 'application/pdf;charset-UTF-8'
        pdfInfoList.push({
          ids: _ids,
          pdfName: '',
        })
      } else {
        if (exportType === 'pdf') {
          name = `${supplierName}_${this.$t('orderInfo.quote')}_${moment().format('YYYYMMDDHHmm')}`
          type = 'application/pdf;charset-UTF-8'
          pdfInfoList.push({
            ids: _ids,
            pdfName: '',
          })
        } else {
          name = `${this.$t('orderInfo.quote')}_${moment().format('YYYYMMDDHHmm')}`
          type = 'application/zip'
          const groups = this.sortClass([...isExportOrder.rows], 'supplierId')
          groups.forEach((arr) => {
            let __ids = arr.map((_item) => _item.id)?.join(',')
            const name = arr[0].supplierName
            pdfInfoList.push({
              ids: __ids,
              pdfName: `${name}_${this.$t('orderInfo.quote')}_${moment().format('YYYYMMDDHHmm')}`,
            })
          })
        }
      }
      try {
        this.pdfLoading = true
        const pdf = await this.$http(
          'ossCmdHtmlToPdf',
          {
            oriUrl: 'Kingsoft/pdf-quotation',
            token: Cookies.get(storageKey.TOKEN),
            format: this.pdf.format,
            landscape: this.pdf.landscape,
            pdfInfoList,
          },
          {
            responseType: 'blob',
          }
        )
        this.pdfLoading = false
        name = name + `.${exportType}`
        downloadBlob(name, type, pdf)
      } catch (err) {
        this.pdfLoading = false
        this.$httpNotify(err)
      }
    },

    //订单交期选择时间
    onDueDateChange(date, dateString) {
      this.form.startDueDate = dateString[0]
      this.form.endDueDate = dateString[1]
    },

    //下单时间选择时间
    onCreateDateChange(date, dateString) {
      this.form.startPresentDate = dateString[0]
      this.form.endPresentDate = dateString[1]
    },

    //排序
    handleChange(value) {
      let sort = value.split('|')
      this.orderField = sort[0]
      this.order = sort[1]
      this.fetch(this.searchForm)
    },

    //订单状态筛选
    onStatusChange(value) {
      this.form.status = value ? value.toString() : ''
    },

    //删除
    async onDelete(id) {
      try {
        await this.$http('orderDel', [id])
        this.fetch()
        this.statusCount()
      } catch (err) {
        this.$httpNotify(err)
      }
    },

    // 复制
    async onCopy(id) {
      this.$router.push({
        path: this.$g.route.order_newOrder,
        query: { modelId: id },
      })
    },

    // 日期时间选择框的选取控制
    moment,
    range(start, end) {
      const result = []
      for (let i = start; i < end; i++) {
        result.push(i)
      }
      return result
    },

    disabledRangeTime(_, type) {
      if (type === 'start') {
        return {
          disabledHours: () => this.range(0, 60).splice(4, 20),
          disabledMinutes: () => this.range(30, 60),
          disabledSeconds: () => [55, 56],
        }
      }
      return {
        disabledHours: () => this.range(0, 60).splice(20, 4),
        disabledMinutes: () => this.range(0, 31),
        disabledSeconds: () => [55, 56],
      }
    },
    // 日期时间选择框的选取控制

    // 输入条数的回车事件
    submit(e) {
      if (!/(^[1-9]\d*$)/.test(e.target.value)) {
        this.$message.error('请输入正整数')
      } else {
        this.pageSize = Number(e.target.value)
        this.onShowSizeChange(e.target.value, 1)
      }
    },
    selectdrop(num) {
      this.customPageSize = num
      this.pageSize = Number(num)
      this.onShowSizeChange(num, 1)
    },
  },
}
</script>
